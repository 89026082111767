<router>
{
    name: 'Update',
}
</router>
<template lang="pug">
v-container(fluid)
    DevopsUpdate
</template>
<script>

import DevopsUpdate from '@/components/devops/update';
// import UserList from '@/components/salestool/userlist.vue';
export default {
    meta: {
        role: 'devopsupdate'
    },
    components: {
        DevopsUpdate,
    },
    data () {
        return {
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
        window.satusersvm = this;
    }
};
</script>

<style scoped>
</style>
